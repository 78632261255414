<template>
  
  <div>
  
    <b-row class="justify-content-end">
      <b-col cols="auto">
        <b-dropdown variant="dark" text="Filtros" class="dropdown-left mb-2 mr-1">
          <template #button-content>
            <feather-icon size="13" icon="FilterIcon" cursor="pointer" />
            Filtros
          </template>
          <b-dropdown-item @click="aplicarFiltro('todas')">Todas</b-dropdown-item>
          <b-dropdown-item @click="aplicarFiltro('abertas')">Abertas</b-dropdown-item>
          <b-dropdown-item @click="aplicarFiltro('proximas')">Próximas</b-dropdown-item>
          <b-dropdown-item @click="aplicarFiltro('fechadas')">Fechadas</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  
  <vue-element-loading
  style="min-height: 500px;"
  :active="carregamentoApi"
  spinner="bar-fade-scale"
  text="Carregando Campanhas"
  />
  
  <div v-if="!campanhas.length && !carregamentoApi">
    
    <b-col
    cols="12"
    style="padding: 0px;"
    >
    <b-card
        :no-body="true"
        class="text-left p-3"
        style="overflow: hidden;"
      >
        
        <b-card-body>

          <div style="position: absolute; top: 0; right: 0;z-index:0;">
            <img src="@/assets/images/bg/bg.png"/>
          </div>

          <div style="position: relative;z-index:2;">
            <b-card-title class="text-white">
              <h1 class="content-header-title">Bem-vindo às <b>campanhas</b></h1>
            </b-card-title>
            <b-card-text class="my-2">
              <h6>
                <b>Aqui você encontrará as audições criadas por artistas. Mas, neste momento, não temos nenhuma campanha ou audição ativa.</b>
              </h6>
            </b-card-text>

          </div>

        </b-card-body>

      </b-card>
    </b-col>

  </div>

    <section
      id="knowledge-base-content"
      class="pb-5"
    >

      <b-row>

        <b-col
          v-for="campanha in campanhasFiltro"
          :key="campanha.id"
          cols="12"
          xl="4"
        >

        <b-card
          class="ecommerce-card"
          no-body
        >
          <div class="item-img text-center">
            <b-link
              v-if="campanha.aberta"
              :to="{ name: 'campanha-compositor-visualizar', params: { id: campanha.id } }"
            >
              <v-lazy-image
                fluid
                class="card-img-top"
                :style="{ filter: campanha.aberta ? 'none' : 'grayscale(100%)' }"
                :src="campanha.arquivo"
                :src-placeholder="require(`@/assets/images/placeholder/campanhas.gif`)"
              />
            </b-link>
            <div v-else>
              <v-lazy-image
                fluid
                class="card-img-top"
                :style="{ filter: 'grayscale(100%)' }"
                :src="campanha.arquivo"
                :src-placeholder="require(`@/assets/images/placeholder/campanhas.gif`)"
              />
            </div>
          </div>

          <!-- Product Details -->
          <b-card-body>
            <h6 class="item-name">

            <div v-if="campanha.aberta">
              <b-link
                :to="{ name: 'campanha-compositor-visualizar', params: { id: campanha.id } }"
                class="text-body"
              >
                {{ campanha.nome }}
              </b-link>
            </div>

            <div v-if="!campanha.aberta">
              <h6>{{ campanha.nome }}</h6>
            </div>
              
            </h6>
            <b-card-text class="item-description">
              Válida de {{ campanha.inicio }} até {{ campanha.fim }}
            </b-card-text>
          </b-card-body>

          <!-- Action Buttons -->
          <div v-if="campanha.aberta" class="item-options text-center">
            <b-button
              variant="primary"
              class="btn-cart move-cart"
              style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
              :to="{ name: 'campanha-compositor-visualizar', params: { id: campanha.id } }"
            >
              <span>Participar</span>
              <b-icon
                icon="arrow-right-short"
                class="mr-50"
              />
            </b-button>
          </div>

          <div v-if="dataFormatada(campanha.fim) > dataFormatada(dataHoje) && !campanha.aberta" class="item-options text-center">
            <b-button
              class="btn-cart move-cart"
              style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
            >
              <span>Em breve</span>
              <b-icon
                icon="bi-clock"
                class="mr-50"
              />
            </b-button>
          </div>

          <div v-if="dataFormatada(campanha.fim) < dataFormatada(dataHoje)" class="item-options text-center">
            <b-button
              class="btn-cart move-cart"
              style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
            >
              <span>Fechado</span>
              <b-icon
                icon="bi-clock"
                class="mr-50"
              />
            </b-button>
          </div>
        </b-card>
        </b-col>

      </b-row>

    </section>

  </div>
</template>

<script>
import {
  BLink,
  BImg, 
  BContainer, 
  BRow, 
  BCol, 
  BCard, 
  BCardTitle, 
  BCardHeader, 
  BCardBody, 
  BForm, 
  BInputGroup, 
  BFormInput, 
  BCardText, 
  BInputGroupPrepend, 
  IconsPlugin, 
  BBadge, 
  BButton, 
  BModal, 
  BFormCheckbox,
  BMediaBody, 
  BMedia, 
  BMediaAside, 
  BAvatar, 
  BDropdown, 
  BDropdownItem,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import moment from 'moment'

import axios from 'axios'

import VLazyImage from 'v-lazy-image/v2';
import { copyFile } from 'fs'


export default {
  components: {
    BLink,
    BImg,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton, 
    BModal, 
    BFormCheckbox,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueElementLoading,
    moment,
    VLazyImage,
  },
  data() {
    return {
      dataHoje: moment().format('DD/MM/YYYY'),
      campanhas: {},
      campanhasFiltro: [],
      carregamentoApi: false,
      filtroAtivo: 'todas'
    }
  },
  computed: {

    // this.hitsLista();

  },
  created() {
    this.campanhaLista()
  },

  methods: {
    aplicarFiltro(tipo) {
      this.filtroAtivo = tipo;
      switch (tipo) {
        case 'todas':
          this.campanhasFiltro = [...this.campanhas];
          break;
        case 'abertas':
          this.campanhasFiltro = this.campanhas.filter((campanha) => campanha.aberta)
          break;
          case 'proximas':
            this.campanhasFiltro = this.campanhas.filter((campanha) => {
              return this.dataFormatada(campanha.fim) > this.dataFormatada(this.dataHoje) && !campanha.aberta
              })
            break
        case 'fechadas':
            this.campanhasFiltro = this.campanhas.filter((campanha) => {
              return this.dataFormatada(campanha.fim) < this.dataFormatada(this.dataHoje)
            })
            break;
        default:
            this.campanhasFiltro = [...this.campanhas];
            break;
      }
    },
    dataFormatada(data) {
      return moment(data, 'DD/MM/YYYY').toDate();
    },
    campanhaLista() {
      this.carregamentoApi = true

      useJwt.campanhaTodosCompositorLista({
        page: 0,
        size: 10,
        sort: 'DESC',
      })
        .then(response => {

          if (response.data.content.length) {
            this.campanhas = response.data.content
            this.aplicarFiltro(this.filtroAtivo);
          } else {
            this.campanhas = {}
          }

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
  },
}
</script>

  <style lang="scss">
  //@import '@core/scss/vue/pages/page-knowledge-base.scss';
  @import "@core/scss/base/pages/app-ecommerce.scss";


  .card-campanha .card-title{
    font-size: 14px;
  }

  .card-campanha .card-subtitle {
    font-size: 12px;
  }

  .dropdown-left .dropdown-menu {
    right: 0 !important; /* Ajusta o dropdown para a direita */
    left: auto !important; /* Reseta o ajuste de posição para a esquerda */
  }

  </style>
